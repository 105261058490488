var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    attrs: {
      "id": "appl"
    }
  }, [_c('div', {
    ref: "chart",
    attrs: {
      "id": "chart"
    }
  }, [_c('apexcharts', {
    attrs: {
      "type": "donut",
      "width": 500,
      "height": 380,
      "options": _vm.chartOptions,
      "series": _vm.chartOptions.series
    }
  })], 1)]), _vm._m(0), _vm._m(1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bottom_description"
  }, [_c('div', {}, [_c('p', {
    staticClass: "total_credit_used"
  }, [_vm._v(" total credit used ")]), _c('p', {
    staticClass: "__number"
  }, [_c('strong', [_vm._v("14,559")])])]), _c('div', {}, [_c('p', {
    staticClass: "__total__contacts"
  }, [_vm._v(" total contacts ")]), _c('p', {
    staticClass: "__number"
  }, [_c('strong', [_vm._v(" 14,559")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__view__more d-flex justify-content-center align-items-center pb-4"
  }, [_c('button', [_vm._v("View more")])]);
}];
export { render, staticRenderFns };